@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
  width: 8px;
  border-radius: 12px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 12px;
}
::-webkit-scrollbar-thumb {
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  background: linear-gradient(135deg, #007d6d 41%, #babd31 100%);
  border-radius: 12px;
}
